import axios from 'axios'
import * as types from './types'
import type { BrowserInfo, CheckoutAdvancedFlowResponse, PaymentMethodsResponse } from '@adyen/adyen-web'

declare const VWO: any

export const triggerVWOTracking = (
  isSuccess: boolean,
  amount: number = 0,
  packageName: string = ''
): void => {
  try {
    if (isSuccess) {
      VWO.event('purchase', {
        revenue: amount,
        checkout: true,
        package: packageName,
      })
    } else {
      VWO.event('purchase', {
        revenue: 0,
        checkout: false,
        package: packageName,
      })
    }
  } catch (error) {
    console.error('Error triggering VWO event:', error)
  }
}

export const getSession = () => {
  return axios.get<any>('/api/v2/session')
}

export const getUserById = (id: string) => {
  return axios.get<types.User>(`/api/v2/users/${id}`)
}

export const getOrder = (body: types.OrderPayload) => {
  return axios.get<types.OrderData>(
    `/api/v2/users/${body?.userId}/orders/${body?.orderId}`
  )
}

type Amount = {
  value:    number
  currency: string
}

type Checkout = {
  gateway:                string
  gatewayKey:             string
  gatewayEnv:             "test" | "live" | "live-us" | "live-au" | "live-apse" | "live-in"
  countryCode:            string
  amount:                 Amount
  paymentMethodsResponse: PaymentMethodsResponse
}

export const getAdyenCheckoutConfig = async (planCodes: string | Array<string>) => {
  return axios.post<Checkout>("/api/v2/payments/adyen/checkouts", { planCodes })
}

type CreateAdyenPaymentRequestPayload = {
  discountCode?: string,
  planCodes: string | string[],
  paymentMethod: object,
  browserInfo?: BrowserInfo,
  riskData?: {clientData: string},
  billingAddress?: object,
  customer: {
    firstName: string,
    lastName: string,
    email: string,
    timezone?: string
  },
  giftee?: {
    firstName: string,
    lastName: string,
    email: string,
    note?: string
  }
}

export interface Purchase {
  id: string
  failure_reason: string
  updated_at: string
  orderIds: number[]
  transactionId: number
  status: string
  createdAt: string
  partnerId: number
  totalUSD: number
  currency: string
  brandName: string
  products: {
    planPrices: {
      planCode: string
      planName: string
      price: number
    }[]
  }
  lead: {
    hashedEmail: string
    age: number
    score: number
  }
}

export const createPurchase = (payload: CreateAdyenPaymentRequestPayload, pt?: string) => {
  return axios.post<Purchase>("/api/v2/payments/adyen/purchases", payload, { params: { pt } })
}

export const getOnePurchase = (purchaseId: string) => {
  return axios.get<Purchase>(`/api/v2/payments/purchases/${purchaseId}`)
}

type AdditionalDetailsRequestPayloadType = {
  details: {
    [key: string]: any;
    redirectResult?: string | undefined;
    threeDSResult?: string | undefined;
  },
  paymentData?: string | undefined;
  sessionData?: string | undefined;
}

export const submitAdditionalPaymentDetails = (payload: AdditionalDetailsRequestPayloadType, purchaseId: string) => {
  return axios.patch<CheckoutAdvancedFlowResponse>(`/api/v2/payments/adyen/purchases/${purchaseId}`, payload)
}

export const calculateProductPrices = (body: types.CalculatePricesPayload) => {
  return axios.post<types.CalculatePricesResponse>(
    '/api/v2/payments/prices/calculate',
    body
  )
}

export const createAffirmCharge = (body: types.CreateAffirmChargePaylod, pt?: string) => {
  return axios.post<types.OrderResponse>("/api/v2/payments/affirm/charge", body, { params: { pt } })
}
