import { ChevronRight, Lock } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { getCurrencySymbol } from '@talentinc/gatsby-theme-ecom/utils/currency'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import useLocation from 'react-use/lib/useLocation'
import { withTwoPassRenderer } from '../../hocs/withTwoPassRenderer'
import { UpsellCategories } from './Categories'
import { FinishedCheckout, useOrderUser } from './FinishedCheckout'
import { useUpsellForm } from './UpsellProvider'
import { STANDARD_DELIVERY } from './data'
import { useUserUpsells } from './queries'
import { useUpsellStyles } from './styles'
import { Upsell } from './types'

const ConfirmState: React.FC<{
  cost: number
  onConfirm: () => void
  onCancel: () => void
  sample: Upsell | Record<string, never>
  enabled?: boolean
}> = ({ cost, enabled, onConfirm, onCancel, sample}) => {
  const { classes } = useUpsellStyles()
  const { currency_code } = sample

  return (
    <Box
      width="100%"
      p={1}
      style={{
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: "10px 20px"
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography
          style={{
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          Please confirm your upgrades.
        </Typography>

        <Typography style={{ fontSize: 17 }}>
          {`You will be billed an additional ${getCurrencySymbol(
            currency_code
          )}${cost} and your upgrades will be added to your order.`}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" gap="10px" mt={5}>
        <Button className={classes.baseButton} onClick={onConfirm} disabled={!enabled}>
          Yes, Upgrade
        </Button>

        <Button variant="text" className={classes.baseButton} onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

const UpsellDisplayCost: React.FC<{
  cost: number
  enabled: boolean
  sample: Upsell | Record<string, never>
  onUpgrade: () => void
}> = ({ cost, enabled, onUpgrade, sample }) => {
  const { classes, cx } = useUpsellStyles()

  const { currency_code } = sample

  const handleOnUpgrade = () => {
    onUpgrade()
  }

  return (
    <Box p={1}>
      <Button disabled={!enabled} className={cx(classes.baseButton, classes.upgradeButton)} onClick={handleOnUpgrade}>
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Lock fontSize="small" />
          Upgrade{cost > 0 ? ` for ${currency_code} ${cost}` : ""}
        </Box>
      </Button>
    </Box>
  )
}

const UpsellPage = () => {
  const [isMounted, setIsMounted] = useState(false)

  const { search } = useLocation()

  const isAffirm = new URLSearchParams(search?.substring(1) ?? '').get('affirm')

  const orderUser = useOrderUser()

  // TODO: Remove this after the BE are able to restore giftee data
  const [giftee, setGiftee] = useState(null)

  useEffect(() => {
    setIsMounted(true)

    // TODO: Remove this after the BE are able to restore giftee data
    try {
      const url = new URLSearchParams(search?.substring(1) ?? '')
      const orderId = url.get('order')
      setConfirmedOrder(url.get('type') === 'upsold')
      const item = localStorage.getItem('giftee' + orderId)

      if (item) {
        const itemParsed = JSON.parse(item as any)
        setGiftee(itemParsed)
      }
    } catch {}
  }, [search])

  const { state } = useUpsellForm()
  const [showConfirmUpgrade, setShowConfirmUpgrade] = useState(false)
  const [confirmedOrder, setConfirmedOrder] = useState(false)
  const { isLoading, dict, isError: isUserUpsellsFetchError, mappedCategories, sample } = useUserUpsells(!isAffirm)

  const totalCost = useMemo(() => {
    if (isLoading) return 0

    let total = 0

    state.activePackages.forEach((id: string) => {
      total += dict[id]?.price || 0
    })

    return total
  }, [isLoading, dict, state.activePackages])

  const selectedRequiredCategories = useMemo(() => {
    const requiredCategories = mappedCategories.filter(
      (category) => category.required
    )

    const activeCategories = requiredCategories.filter((category) => {
      const activeItems = category.items.filter((itemId) =>
        state.activePackages.has(itemId)
      )

      return activeItems.length > 0
    })
    return requiredCategories.length === activeCategories.length
  }, [state.activePackages, mappedCategories])

  const handleShowConfirmUpgrade = () => {
    setShowConfirmUpgrade(true)
  }

  const handleSubmitUpgrade = async (skipCharge?: boolean) => {
    if (!skipCharge && totalCost > 0) {
      const plansList = Array.from(state.activePackages).filter((id) => id !== STANDARD_DELIVERY) as string[]
      navigate(`/purchase/${plansList.join(",")}?type=upsold`)
      return
    }

    window?.scrollTo(0, 0)
    setConfirmedOrder(true)
  }

  const handleCancelUpgrade = () => {
    setShowConfirmUpgrade(false)
  }

  useEffect(() => {
    isUserUpsellsFetchError && navigate("/")
  }, [isUserUpsellsFetchError])

  if (!isMounted) {
    return null
  }

  if (confirmedOrder || isAffirm || orderUser.data?.user_has_submitted_questionnaire)
    return (
      <FinishedCheckout
        hasUpgrades={totalCost > 0}
        isForSomeoneElse={!!giftee}
        alreadySubmited={orderUser.data?.user_has_submitted_questionnaire}
      />
    )

  return (
    <Box display="flex" flexDirection="column" gap="40px" width="100%">
      <Box display="flex" flexDirection="row" gap="30px" alignItems="center">
        <Typography variant="h2">Upgrade your package</Typography>
      </Box>

      <UpsellCategories />

      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="end" minHeight={170}>
        {showConfirmUpgrade ? (
          <ConfirmState
            sample={sample}
            onConfirm={() => handleSubmitUpgrade(false)}
            onCancel={handleCancelUpgrade}
            cost={totalCost}
            enabled={selectedRequiredCategories}
          />
        ) : (
          <>
            <UpsellDisplayCost
              sample={sample}
              onUpgrade={handleShowConfirmUpgrade}
              cost={totalCost}
              enabled={selectedRequiredCategories}
            />

            <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 800,
                  color: "#1a91f0",
                  cursor: 'pointer',
                }}
                onClick={() => handleSubmitUpgrade(true)}
              >
                Skip Upgrades
              </Typography>
              <ChevronRight style={{ color: '#1a91f0' }} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default withTwoPassRenderer(UpsellPage)
