import { getOrder } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/api'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getSession } from '../NewCheckout/api'
import * as api from './api'
import { PRODUCT_CATEGORIES, SINGLE_STANDARD_DELIVERY, STANDARD_DELIVERY } from "./data"
import { Upsell } from './types'
import { logError } from './utils'

const filterUpsellIconsToPlans = (upsells: Record<string, Upsell>, filterMethod: (upsell: Upsell) => boolean) =>
  Object.values(upsells)
    .filter(filterMethod)
    .map(({ plan_code }) => plan_code)

export const useOrderData = (args: { userId?: string; orderId?: string }) => {
  return useQuery({
    queryKey: ['orderData', args.userId, args.orderId],
    refetchOnWindowFocus: false,
    queryFn: () => getOrder(args),
    select: ({ data }) => data,
    enabled: !!args?.userId && !!args?.orderId,
    onError: (error) => logError('Error retrieving products:', error),
  })
}

const EXCLUDED_UPSELLS = [
  'RMyW8QF',
  'RMyW8QF',
  'gL0Jl3I',
  'lK5awqN',
  'F14tuz',
  '9ms3NO',
  'EBrDXfg',
  '9W27rRa',
  'V39GiQ0',
  'F0JSHem',
  'oDHUIg9',
  'cetXSps',
  'PZSkvt',
] // plan codes for Resume Rabbit and Resume Distribution

export const useUserUpsells = (enabled = true) => {
  const query = useQuery({
    queryKey: ['userUpsells'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const session = await getSession()
      return api.fetchUserUpsells(session.data.lead_auth_token as string)
    },
    placeholderData: { data: [] },
    select: ({ data }: { data: Upsell[] }) =>
      data.filter((upsell) => !EXCLUDED_UPSELLS.includes(upsell.plan_code)),
    onError: (error) => logError('Error retrieving client token:', error),
    enabled,
  })

  const { data, isLoading } = query

  const dictUpsellItems = useMemo(() => {
    if (isLoading) return {}
    if (!data) return {}

    const dataDict = data?.reduce(
      (acc, upsell) => {
        acc[upsell.plan_code] = upsell
        return acc
      },
      { [STANDARD_DELIVERY]: SINGLE_STANDARD_DELIVERY } as Record<string, Upsell>
    )

    return dataDict
  }, [isLoading, data])

  const dataSample = useMemo<Upsell | Record<string, never>>(() => {
    if (isLoading) return {}
    if (!data) return {}

    return data?.at(0) || {}
  }, [isLoading, data])

  const mappedCategories = useMemo(() => {
    const takenItems = PRODUCT_CATEGORIES.map(({ items }) => items).flat()

    return PRODUCT_CATEGORIES.map((category) => {
      const { items, ...rest } = category

      const filterCond =
        items.at(0) === '*'
          ? ({ icon_url }: Upsell) => !takenItems.includes(icon_url as string)
          : ({ icon_url }: Upsell) => !!(icon_url && items.includes(icon_url))

      return {
        ...rest,
        items: filterUpsellIconsToPlans(dictUpsellItems, filterCond),
      }
    })
  }, [dictUpsellItems])

  return {
    ...query,
    dict: dictUpsellItems,
    sample: dataSample,
    mappedCategories,
  }
}
