import { Upsell, UpsellCategoryProps } from "./types"

export const STANDARD_DELIVERY = "standard-delivery"

export const SINGLE_STANDARD_DELIVERY = {
  id: 9999999,
  name: "Standard Delivery",
  plan_code: STANDARD_DELIVERY,
  price: 0,
  original_price: 0,
  short_pitch: "",
  very_short_pitch: "",
  icon_url: "Rush.svg",
  package: {
    id: 27,
    name: "Standard Delivery",
    description: null,
    item_names: "Standard Delivery",
    item_type_keys: "interview-prep",
    created_at: "2020-06-11T15:34:21Z",
    updated_at: "2020-06-11T15:34:21Z",
  },
  items: [
    {
      id: 48,
      item_id: 17,
      package_id: 27,
      quantity: 1,
      name: "Standard Delivery",
      item_type_key: "standard-delivery",
      upsell_priority: 2,
    },
  ],
  created_at: "2017-07-24T15:06:02Z",
  purchases_today: 0,
  currency_code: "USD",
  currency_symbol: "$",
  oms_fulfillable_items: 1,
  upsell_priority: 2,
  bundle_groups: [],
  bundle: null,
} as Upsell

export const PRODUCT_CATEGORIES = [
  {
    id: "first-group",
    title: "1. Prioritize your order.",
    items: [SINGLE_STANDARD_DELIVERY.icon_url],
    required: true,
  },
  {
    id: "second-group",
    title: "2. Be prepared",
    items: ["*"],
  },
] as UpsellCategoryProps[]
