import { makeStyles } from 'tss-react/mui'

export const upsellLayoutStyles = {
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  pageContainer: {
    flex: 1,
    bgcolor: '#EFF2F9',
    height: '100%',
    paddingBottom: 60,
  },
}

export const useUpsellStyles = makeStyles()((theme) => ({
  itemContainer: {
    width: 'calc(50% - 20px)',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 20px',
    transition: 'box-shadow .3s',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  baseButton: {
    borderRadius: 5,
    '&:focus': { outline: 'none' },
  },
  upgradeButton: {
    borderRadius: 5,
  },
  purchaseButtonActive: {
    backgroundColor: theme.colors.blue[300],
    color: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.colors.blue[400],
    },
  },
  purchaseButton: {
    borderRadius: 1,
    backgroundColor: 'transparent',
    outline: `1px ${theme.colors.blue[300]} solid`,
    padding: '5px 10px',

    '&:hover': {
      backgroundColor: 'rgba(26, 145, 240, 0.05)',
    },
    '&:focus': { outline: 'none' },

    '&$purchaseButtonActive': {
      backgroundColor: theme.colors.blue[300],
      color: 'white',
      '&:hover': {
        backgroundColor: theme.colors.blue[400],
      },
    },
  },
  detailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      gap: 10,
      alignItems: 'start',
    },
  },
  styledBox: {
    [theme.breakpoints.down('md')]: {
      '& > div': {
        marginBottom: '20px',
      },
    },
  },
  showHide: {
    overflow: 'hidden',
    transition: 'max-height 0.3s',
  },
}))
