import React, { useMemo, createElement } from "react"
import { Box, Button, Typography } from "@mui/material"
import {
  ImportContacts,
  CheckCircleOutlined,
  FingerprintOutlined,
  EditOutlined,
  StarOutlined,
} from "@mui/icons-material"

import useUser from '@talentinc/gatsby-theme-ecom/hooks/useUser'
import Link from '@talentinc/gatsby-theme-ecom/components/Link'
import useLocation from 'react-use/lib/useLocation'
import { useOrderData } from './queries'
import { useUpsellStyles } from './styles'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'

export const useOrderUser = () => {
  const { search } = useLocation()
  const orderId = useMemo(() => {
    return new URLSearchParams(search?.substring(1) ?? "").get("order")
  }, [search]) as string
  const { lead_auth_token } = useUser()

  return useOrderData({ orderId, userId: lead_auth_token as string })
}

const BtnContinueToQuestionnaire = (props: { alreadySubmited: boolean }) => {
  const { data, error } = useOrderUser()
  const { name } = useBrand()
  const { classes } = useUpsellStyles()

  const { text, href, disabled } = useMemo(() => {
    if (error || props.alreadySubmited || !data?.questionnaire_link) {
      return {
        text: "Continue to customer portal",
        href: `https://${name}.portal.careers`,
        disabled: false,
      }
    }

    return {
      text: "Continue to questionnaire",
      href: data.questionnaire_link,
      disabled: false,
    }
  }, [data, error, props.alreadySubmited, name])

  return (
    <Link
      style={{
        width: 'fit-content',
        pointerEvents: disabled ? 'none' : undefined,
      }}
      href={href}
    >
      <Button style={{ width: "fit-content" }} className={classes.baseButton} disabled={disabled}>
        {text}
      </Button>
    </Link>
  )
}

export const FinishedCheckoutItem: React.FC<{
  icon: typeof ImportContacts
  title: string
  description: string
}> = ({ icon, title, description }) => {
  const elementIcon = createElement(icon, {
    style: { fontSize: 22 },
  })

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          {elementIcon}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box width="calc(100% - 20px)">
          <Typography style={{ fontSize: 18 }}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const FinishedCheckout: React.FC<{
  hasUpgrades?: boolean
  isForSomeoneElse?: boolean
  alreadySubmited?: boolean
}> = ({ hasUpgrades, alreadySubmited = false, isForSomeoneElse = false }) => {
  useOrderUser()
  const { classes } = useUpsellStyles()

  return (
    <Box display="flex" justifyContent="center">
      <Box
        mt={10}
        display="flex"
        flexDirection="column"
        gap="50px"
        width="calc(100% - 5rem)"
      >
        <Box display="flex" flexDirection="column">
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              flexDirection: { xs: 'column', sm: 'row' },
              gridGap: 20,
            }}
            alignItems="center"
          >
            <CheckCircleOutlined fontSize="large" htmlColor="#2cd265" />
            <Typography
              style={{
                fontSize: 39,
                fontWeight: 'bolder',
                letterSpacing: -1.5,
                color: '#2cd265',
              }}
            >
              We have received your order{hasUpgrades ? " and upgrades" : ""}.
            </Typography>
          </Box>

          <Typography>
            {isForSomeoneElse
              ? "Here's what your order recipient can expect as we begin to work on their order:"
              : "Here's what to expect as we begin work on your order:"}
          </Typography>
        </Box>

        <Box
          className={classes.styledBox}
          sx={{
            display: { xs: 'block', sm: 'flex' },
            flexDirection: { xs: 'column', sm: 'row' },
            gridGap: 3,
          }}
        >
          <FinishedCheckoutItem
            icon={FingerprintOutlined}
            title="Getting to Know You"
            description={
              isForSomeoneElse
                ? 'We will email them a brief questionnaire about their goals and anything missing from their resume.'
                : 'Fill out a brief questionnaire about your goals and anything missing from your resume.'
            }
          />
          <FinishedCheckoutItem
            icon={EditOutlined}
            title="Writing"
            description={
              isForSomeoneElse
                ? "After getting to know them, we'll take a first crack at your order."
                : "After getting to know you, we'll take a first crack at your order."
            }
          />
          <FinishedCheckoutItem
            icon={StarOutlined}
            title="Satisfaction Guarantee"
            description={
              isForSomeoneElse
                ? 'We will go back and forth with them until they are happy!'
                : "We will go back and forth with you until you're happy!"
            }
          />
        </Box>

        {isForSomeoneElse ? null : <BtnContinueToQuestionnaire alreadySubmited={alreadySubmited} />}
      </Box>
    </Box>
  )
}
