import React from "react"

import AutoApply from "../../../images/billing/AutoApply.svg"
import DocumentIcon from "../../../images/billing/Document.svg"
import DeliveryIcon from "../../../images/billing/Delivery.svg"
import LinkedInIcon from "../../../images/billing/LinkedIn.svg"
import GuaranteeIcon from "../../../images/billing/Guarantee.svg"
import TopInterviewIcon from "../../../images/billing/TopInterview.svg"
import ResumeRabbitIcon from "../../../images/billing/ResumeRabbit.svg"
import PhoneConsultationIcon from "../../../images/billing/PhoneConsultation.svg"
import RecruiterDistributionIcon from "../../../images/billing/RecruiterDistribution.svg"

const ITEM_MAP_URL = {
  "CoverLetter.svg": DocumentIcon,
  "Default.svg": AutoApply,
  "LinkedIn.svg": LinkedInIcon,
  "PhoneConsultation.svg": PhoneConsultationIcon,
  "Recruiter.svg": RecruiterDistributionIcon,
  "ResumeRabbit.svg": ResumeRabbitIcon,
  "Rush.svg": DeliveryIcon,
  "ThankYouLetter.svg": DocumentIcon,
  "TopInterview.svg": TopInterviewIcon,
}

export type UpsellItemType = keyof typeof ITEM_MAP_URL

export const UpsellIcon: React.FC<{ id: UpsellItemType }> = ({ id }) => {
  return (
    <img
      style={{
        width: 60,
        height: 60,
        color: "#1a91f0",
      }}
      src={ITEM_MAP_URL[id] || GuaranteeIcon}
      alt={id}
    />
  )
}
